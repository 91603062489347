import React from 'react';
import NavTop from '../../components/NavTop';
import NavTeam from '../../components/NavTeam';
import FooterButtons from '../../components/FooterButtons';
import InputCheckbox from '../../components/InputCheckbox';
import InputText from '../../components/InputText';
import FilterBar from '../../components/FilterBar';

const EditTable = ({ local, playerDatabase, onChange, onSave, onDelete, onRefresh, onCreate, onAdd, onSearchKeyUp, disable, hasChanged, currentYear, currentTeam }) => {
  return (
    <div className="container">
      <NavTop currentYear={currentYear} currentTeam={currentTeam} currentTeamPage="roster" />
      <NavTeam page="roster" currentYear={currentYear} currentTeam={currentTeam} />
      <div className="col-sm-6">
        <form>
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="medium">Player</th>
                <th className="medium">Jersey</th>
                <th className="narrow">Active</th>
                <th className="medium">End Year</th>
                <th className="medium">Remove</th>
              </tr>
            </thead>
            <tbody>
              {local.map((entry, arrayIndex) =>
                <tr key={entry.id}>
                  <td className="medium">{entry.player.first_name + ' ' + entry.player.last_name}</td>
                  <td className="medium">
                    <InputText
                      type="text"
                      name="jersey_number"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="narrow center"><InputCheckbox
                    name="active"
                    disabled={disable}
                    entry={entry}
                    handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="medium">
                    <InputText
                      type="number"
                      name="endYear"
                      disabled={disable}
                      entry={entry}
                      handleChange={(e) => onChange({ event: e, id: entry.id })} />
                  </td>
                  <td className="medium">
                    {entry.insert ? <button
                      type="button"
                      className="btn btn-danger"
                      name="delete"
                      onClick={() => onDelete(entry.id)}>
                      Remove
                    </button> : null}
                  </td>
                </tr>,
              )}
            </tbody>
          </table>
          <FooterButtons
            hasChanged={hasChanged}
            disabled={disable}
            onSave={onSave}
            onRefresh={onRefresh}
          />
        </form>
      </div>
      <div className="col-sm-6">
        <FilterBar name="playerFilterBar" onSearchKeyUp={onSearchKeyUp} />
        <button type="button" disabled={disable} className="btn btn-primary add-player-btn" data-toggle="modal" data-target="#newPlayer">Create and add new player</button>
        <table id="playerTable" className="table table-striped">
          <thead>
            <tr>
              <th className="medium">First Name</th>
              <th className="medium">Last Name</th>
              <th className="xlarge">Team</th>
              <th className="medium">Add</th>
            </tr>
          </thead>
          <tbody>
            {playerDatabase.filter((entry) => !entry.hidden).map((entry, arrayIndex) =>
              <tr key={entry.id}>
                <td className="narrow">{entry.first_name}</td>
                <td className="narrow">{entry.last_name}</td>
                <td className="xlarge">{entry.teams[0]}</td>
                <td className="medium">
                  <button
                    type="button"
                    className="btn btn-success"
                    disabled={disable}
                    onClick={(e) => onAdd(e, entry.id, entry.first_name, entry.last_name)}
                    data-dismiss="modal">Add to Roster</button></td>
              </tr>,
            )}
          </tbody>
        </table>
      </div>
      <div id="newPlayer" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <form>
            <div className="modal-content">
              <div className="modal-header">
                <h2>Add a new player</h2>
              </div>
              <div className="modal-body">
                <div className="prompt-entry">
                  <span>First Name:</span>
                  <input type="text" placeholder="First Name" id="newPlayerFirstName" />
                </div>
                <div className="prompt-entry">
                  <span>Last Name:</span>
                  <input type="text" placeholder="Last Name" id="newPlayerLastName" />
                </div>
                <div className="prompt-entry">
                  <span>Jersey Number:</span>
                  <input type="text" placeholder="Jersey Number" id="newJerseyNumber" />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={onCreate}
                  data-dismiss="modal">Add Player</button>
                <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditTable;
